import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

import {trackDownloadEvent} from '../utils/analytics'
import AppleIcon from '../assets/images/apple-icon.inline.svg'
import MicrosoftIcon from '../assets/images/microsoft-icon.inline.svg'

const DownloadButton = ({platform, className}) => {
  if (platform === 'macOS') {
    return (
      <a
        href="/download/mac/"
        onClick={trackDownloadEvent}
        className={'btn btn--download ' + className}
      >
        <AppleIcon className="btn__apple-icon"/>
        <span className="btn__download-label">Download for</span>
        <span className="btn__platform-label">macOS</span>
      </a>
    )
  }

  return (
    <Link
      to="/waiting-list/"
      className={'btn btn--download ' + className}
    >
      <MicrosoftIcon className="btn__microsoft-icon"/>
      <span className="btn__download-label">Download for</span>
      <span className="btn__platform-label">Windows</span>
    </Link>
  )
}

DownloadButton.propTypes = {
  platform: PropTypes.oneOf(['macOS', 'Windows']).isRequired,
  className: PropTypes.string
}

export default DownloadButton
