import React from 'react'
import PropTypes from 'prop-types'

import PlayIcon from '../assets/images/play-icon.inline.svg'
import ReplayIcon from '../assets/images/replay-icon.inline.svg'

class Video extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isPlaying: false,
    }
    this.videoRef = React.createRef()
  }

  playVideo = () => {
    if (this.state.isPlaying) return
    if (this.videoRef.current.currentTime > 0) this.videoRef.current.currentTime = 0
    this.videoRef.current.play()
    this.setState({isPlaying: true})
  }

  onEnded = () => {
    this.setState({isPlaying: false})
  }

  render () {
    let playIcon
    if (!this.state.isPlaying) {
      if (this.videoRef.current && this.videoRef.current.currentTime > 0)
        playIcon = <ReplayIcon className="video__play-icon"/>
      else
        playIcon = <PlayIcon className="video__play-icon"/>
    }
    return (
      <div className="video">
        <video
          muted
          playsInline
          poster={this.props.posterSrc}
          onClick={this.playVideo}
          onEnded={this.onEnded}
          ref={this.videoRef}
          className="video__element"
        >
          <source src={this.props.src} type="video/mp4"/>
        </video>
        {playIcon}
      </div>
    )
  }
}

Video.propTypes = {
  src: PropTypes.string.isRequired,
  posterSrc: PropTypes.string.isRequired
}

export default Video
