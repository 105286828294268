import React from 'react'

import Layout from '../components/layout'
import Meta from '../components/meta'
import DownloadButton from '../components/download-button'
import Video from '../components/video'
import heroImage from '../assets/images/hero-image.jpg'
import annotationToolsPoster from '../assets/images/annotation-tools-poster.jpg'
import cloudPoster from '../assets/images/cloud-poster.jpg'
import copyPoster from '../assets/images/copy-poster.jpg'
import annotationToolsDemo from '../assets/videos/annotation-tools-demo.mp4'
import cloudDemo from '../assets/videos/cloud-demo.mp4'
import copyDemo from '../assets/videos/copy-demo.mp4'
import PublicCloudIcon from '../assets/images/public-cloud-icon.inline.svg'
import PrivateCloudIcon from '../assets/images/private-cloud-icon.inline.svg'
import TeamIcon from '../assets/images/team-icon.inline.svg'
import CommentsIcon from '../assets/images/comments-icon.inline.svg'
import MobileIcon from '../assets/images/mobile-icon.inline.svg'
import CogsIcon from '../assets/images/cogs-icon.inline.svg'
import logoExampleImage from '../assets/images/logo-example-image.png'
import linkExampleImage from '../assets/images/link-example-image.png'
import CheckIcon from '../assets/images/check-icon.inline.svg'


class IndexPage extends React.Component {
  render () {
    return (
      <>
        <Meta/>
        <Layout>
          <div className="banner">
            <div className="limiter flex-container">
              <div className="flex-item">
                <h1 className="banner__heading">
                  Effective visual communication <br className="wide-screen-br"/>
                  through your cloud storage
                </h1>
                <p className="banner__subheading">
                  Screen capture tool that enhances cloud <br className="wide-screen-br"/>
                  storage capabilities. Designed for both <br className="wide-screen-br"/>
                  teams and individuals.
                </p>
                <div className="banner__btn-holder">
                  <DownloadButton platform="macOS" className="banner__btn"/>
                  <DownloadButton platform="Windows" className="banner__btn"/>
                </div>
              </div>
              <div className="flex-item flex-item--hero-image">
                <div className="hero-image">
                  <img
                    src={heroImage}
                    className="hero-image__element"
                    alt="ScreenTray – screen capture software"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="helper-overlay helper-overlay--bordered"/>
            <div className="limiter flex-container">
              <div className="flex-item flex-item--video">
                <Video src={annotationToolsDemo} posterSrc={annotationToolsPoster}/>
              </div>
              <div className="flex-item flex-item--description">
                <h2 className="section__heading">Powerful Annotation Tools</h2>
                <p className="section__paragraph">
                  Quickly add comments, highlight what <br className="wide-screen-br"/>
                  matters or blur any sensitive content.
                </p>
                <p className="section__paragraph">
                  ScreenTray provides a simple and easy-to-use interface designed for quick annotations.
                </p>
              </div>
            </div>
          </div>
          <div className="section section--colored">
            <div className="helper-overlay helper-overlay--colored helper-overlay--inverse"/>
            <div className="limiter flex-container">
              <div className="flex-item flex-item--description">
                <h2 className="section__heading">Make Even Better Use of Your Cloud</h2>
                <p className="section__paragraph">
                  Instantly upload screenshots to your cloud storage by a single mouse click,
                  or by the use of associated keyboard shortcuts. <br className="wide-screen-br"/>
                  A link to the screenshot will be copied to your
                  clipboard automatically for easy sharing.
                </p>
                <p className="section__paragraph">
                  Works with Google Drive and Dropbox.
                </p>
                <div className="cloud-icons">
                  <div className="cloud-icon cloud-icon--drive"/>
                  <div className="cloud-icon cloud-icon--dropbox"/>
                </div>
              </div>
              <div className="flex-item flex-item--video">
                <Video src={cloudDemo} posterSrc={cloudPoster}/>
              </div>
            </div>
            <div className="cloud-features limiter">
              <div className="cloud-feature">
                <PublicCloudIcon/>
                <h3 className="cloud-feature__heading">Public Uploads</h3>
                <p className="cloud-feature__paragraph">
                  Upload screenshots to a public <br className="wide-screen-br"/>
                  folder to make them available <br className="wide-screen-br"/>
                  to everyone who has a link
                </p>
              </div>
              <div className="cloud-feature">
                <PrivateCloudIcon/>
                <h3 className="cloud-feature__heading">Private Uploads</h3>
                <p className="cloud-feature__paragraph">
                  Use private folders for sharing <br className="wide-screen-br"/>
                  sensitive information with your <br className="wide-screen-br"/>
                  team or saving notes to yourself
                </p>
              </div>
              <div className="cloud-feature">
                <TeamIcon/>
                <h3 className="cloud-feature__heading">
                  Google Shared Drives & <br className="wide-screen-br"/>
                  Dropbox Team Folders Support
                </h3>
                <p className="cloud-feature__paragraph">
                  Store your team’s screenshots <br className="wide-screen-br"/>
                  with all other files in one shared space
                </p>
              </div>
              <div className="cloud-feature">
                <CommentsIcon/>
                <h3 className="cloud-feature__heading">Comments</h3>
                <p className="cloud-feature__paragraph">
                  Collaborate with others <br className="wide-screen-br"/>
                  by having them comment <br className="wide-screen-br"/>
                  directly on your screenshots
                </p>
              </div>
              <div className="cloud-feature">
                <MobileIcon/>
                <h3 className="cloud-feature__heading">Mobile Apps</h3>
                <p className="cloud-feature__paragraph">
                  View, comment and manage <br className="wide-screen-br"/>
                  screenshots using mobile apps <br className="wide-screen-br"/>
                  of your cloud provider
                </p>
              </div>
              <div className="cloud-feature">
                <CogsIcon/>
                <h3 className="cloud-feature__heading">
                  Integration with Other <br className="wide-screen-br"/>
                  Related Services
                </h3>
                <p className="cloud-feature__paragraph">
                  Attach screenshots from Google Drive <br className="wide-screen-br"/>
                  in Gmail, Docs, Sheets, Slides, etc.
                </p>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="helper-overlay"/>
            <div className="limiter flex-container">
              <div className="flex-item flex-item--video">
                <Video src={copyDemo} posterSrc={copyPoster}/>
              </div>
              <div className="flex-item flex-item--description">
                <h2 className="section__heading">Other Ways of Sharing</h2>
                <p className="section__paragraph">
                  Use drag & drop or copy & paste <br className="wide-screen-br"/>
                  functionalities to quickly share your <br className="wide-screen-br"/>
                  screenshots through third-party apps <br className="wide-screen-br"/>
                  without uploading them to the cloud.
                </p>
              </div>
            </div>
          </div>
          <div className="section section--colored">
            <div className="helper-overlay helper-overlay--colored helper-overlay--inverse"/>
            <div className="flex-container flex-container--brand">
              <div className="flex-item flex-item--brand-description">
                <h2 className="section__heading">Increase Brand Recognition</h2>
                <p className="section__paragraph">
                  Add a company logo to screenshots <br/>
                  and show your brand to all recipients
                </p>
              </div>
              <div className="flex-item flex-item--brand-image">
                <div className="brand-image">
                  <img
                    src={logoExampleImage}
                    className="brand-image__element"
                    alt="Add a company logo to screenshots"
                  />
                </div>
              </div>
            </div>
            <div className="flex-container flex-container--brand">
              <div className="flex-item flex-item--brand-image">
                <div className="brand-image">
                  <img
                    src={linkExampleImage}
                    className="brand-image__element"
                    alt="Custom links to screenshots stored in cloud storage"
                  />
                </div>
              </div>
              <div className="flex-item flex-item--brand-description">
                <h2 className="section__heading">Build Brand Trust</h2>
                <p className="section__paragraph">
                  Create more trustworthy links <br/>
                  using your company domain
                </p>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="helper-overlay"/>
            <h2 className="section__heading ta-center">Affordable Pricing</h2>
            <div className="pricing">
              <div className="pricing-plan">
                <h4 className="pricing-plan__heading">Free version</h4>
                <div className="pricing-plan__price">$0</div>
                <ul className="pricing-features">
                  <li className="pricing-feature">
                    <CheckIcon className="pricing-feature__icon"/>
                    All features
                  </li>
                  <li className="pricing-feature">
                    <CheckIcon className="pricing-feature__icon"/>
                    Free updates
                  </li>
                  <li className="pricing-feature pricing-feature--unavailable">No watermark</li>
                  <li className="pricing-feature pricing-feature--unavailable">Custom logo</li>
                </ul>
                <div>
                  <a
                    href="/download"
                    className="btn btn--outline pricing-plan__btn"
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="pricing-plan pricing-plan--primary">
                <h4 className="pricing-plan__heading">Full version</h4>
                <div className="pricing-plan__price">$9.99</div>
                <div className="pricing-plan__hint">(one-time purchase)</div>
                <ul className="pricing-features">
                  <li className="pricing-feature">
                    <CheckIcon className="pricing-feature__icon"/>
                    All features
                  </li>
                  <li className="pricing-feature">
                    <CheckIcon className="pricing-feature__icon"/>
                    Free updates
                  </li>
                  <li className="pricing-feature">
                    <CheckIcon className="pricing-feature__icon"/>
                    No watermark
                  </li>
                  <li className="pricing-feature">
                    <CheckIcon className="pricing-feature__icon"/>
                    Custom logo
                  </li>
                </ul>
                <div>
                  <button
                    disabled
                    className="btn btn--primary pricing-plan__btn"
                  >
                    Buy License
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default IndexPage