const trackDownloadEvent = () => {
  if (!window || !window.gtag) return
  window.gtag('event', 'Click', {
    'event_category': 'Download'
  })
}

const trackOpenCheckout = () => {
  if (!window || !window.gtag) return
  window.gtag('event', 'Open', {
    'event_category': 'Checkout'
  })
}

const trackCompleteCheckout = (price) => {
  if (!window || !window.gtag) return
  window.gtag('event', 'Complete', {
    'event_category': 'Checkout',
    'event_label': 'Paid',
    'value': price
  })
}

module.exports = {
  trackDownloadEvent,
  trackOpenCheckout,
  trackCompleteCheckout
}